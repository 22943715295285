import { MouseEvent, useCallback, useMemo } from 'react';

import { CmsApiS3FileLink } from 'modules/cms/api/types/CmsApiLink';
import { useIsLoggedIn } from 'store/hooks/useIsLoggedIn';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { fileIdToUrl } from 'utils/s3file';
import { showModal } from 'zustand-stores/modalStore';

type Props = {
  eventName: string;
  eventProperties: Record<string, unknown>;
  buttonData: CmsApiS3FileLink;
};

export function useCmsDownloadPermissions(props: Props) {
  const { buttonData, eventName, eventProperties } = props;

  const isLoggedIn = useIsLoggedIn();

  const allowDownload = useMemo(
    () => buttonData.data.permission === 'PUBLIC' || isLoggedIn,
    [buttonData, isLoggedIn],
  );

  const href = useMemo(
    () => (allowDownload ? fileIdToUrl(buttonData.data.s3FileId) : '#'),
    [allowDownload, buttonData.data.s3FileId],
  );

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!allowDownload) {
        e.preventDefault();
        e.stopPropagation();
        const redirectTo = `${window.location.pathname}${window.location.search}`;
        showModal('LOGIN', {
          redirectTo,
        });
      } else {
        trackClicked(eventName, {
          url: href,
          is_download_button: true,
          ...eventProperties,
        });
      }
    },
    [href, allowDownload, eventName, eventProperties],
  );

  return { onClick, href, download: true };
}
