import { ComponentProps, ReactElement, cloneElement } from 'react';

import { Box } from 'components/Box';
import { AnchorButton } from 'components/Button/AnchorButton';
import { Icon } from 'components/Icon/Icon';
import { CmsApiS3FileLink } from 'modules/cms/api/types/CmsApiLink';
import { useCmsDownloadPermissions } from 'modules/cms/hooks/useCmsDownloadPermissions';

type Props = {
  buttonData: CmsApiS3FileLink;
  eventProperties: Record<string, unknown>;
  children: ReactElement<ComponentProps<typeof AnchorButton>>;
};

export function CmsDownloadButton(props: Props) {
  const { buttonData, eventProperties, children } = props;

  const { onClick, href, download } = useCmsDownloadPermissions({
    buttonData,
    eventName: 'CMS Block',
    eventProperties,
  });

  return cloneElement(
    children,
    {
      onClick,
      href,
      'data-qa-id': 'cms-download-button-link',
    },
    <Box display="flex" alignItems="center">
      {download && <Icon name="download" size={18} />}
      <Box ml="8px">{download ? `  ${buttonData.text}` : buttonData.text}</Box>
    </Box>,
  );
}
